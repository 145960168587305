import { IBaseModalFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

// Component
import BaseModal from './BaseModal'

const ContentfulBaseModal = withContentful<IBaseModalFields>(BaseModal, {
  id: (props) => props.sys.id,
  entryTitle: (props) => props.fields.entryTitle,
  heading: (props) => {
    return <BlockRenderer block={props.fields.heading} fieldPath={'.heading'} />
  },
  subHeading: (props) => {
    return <BlockRenderer block={props.fields.subHeading} fieldPath={'.subHeading'} />
  },
  components: (props) => {
    return <BlockRenderer block={props.fields.components} fieldPath={'.components'} />
  },
  disableAnalytics: (props) => props.fields.disableAnalytics,
})

export default ContentfulBaseModal
